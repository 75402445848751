import { Card, Accordion } from "react-bootstrap";
import { titleCase } from "../../utils/utility/commenFunctions";
import React, { useState } from "react"
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Penalties } from "../../utils/constant";

function PenaltyTabDataMulticity(props) {

    const [tabIdex, setIndex] = useState(null)
    const [showDialog, setShowDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const fareRulesError = useSelector((state) => state.searchForm.fareRulesError);
    const fareRulesLoader = useSelector((state) => state.searchForm.fareRulesLoader);
    const lang = useSelector( (state) => state.lang.lang)
    let flowFrom = props.flowFrom

    const handleClick = (i) => {
        if (tabIdex === i) {
            setIndex(null)
        } else {
            setIndex(i)
        }

    }

    let leftData = props.leftData
    const uniqueIds = [];
    // if (leftData !== Penalties.STR_FARE_RULES_ERROR) {
    //     let uniqueFareRules = leftData && leftData.filter(element => {
    //         const isDuplicate = uniqueIds.includes(element.fareBasisCode);
    //         if (!isDuplicate) {
    //             uniqueIds.push(element.fareBasisCode);
    //             return true;
    //         }
    //         return false;
    //     });
    //     leftData = uniqueFareRules
    // }
    const scrollTo = (data) => {

        document.getElementById(data).scrollIntoView({ behavior: "smooth" });
    }

    const handleErrorClick = (message) => {
        setShowDialog(true)
        setDialogMessage(message)
    }

    const handleOk = () => {
        setShowDialog(false);
    }

    const handleClose = () => {
        setShowDialog(false);
    } 

    const { t } = useTranslation();
    return (
        <>
            <div className="penaltyData">
                {!fareRulesError ?
                    <>
                        {leftData !== undefined ?
                            <>
                                {!fareRulesLoader || (leftData && leftData.length === 0) ?
                                    <div style={{ fontSize: "12px" }} className="ruleData">
                                        <div className="penalty-error-msg">
                                            <i className="fa fa-exclamation-circle" style={{ marginTop: "-2.063rem" }}></i>
                                            <span style={{ position: "relative", bottom: "7px" }}>{t('112')}<br/>{t('156')} </span>
                                        </div>
                                    </div>
                                    :
                                    <div className="fare-basic-bold">
                                        <span>
                                            {t("Fare Basis")}
                                        </span>
                                    </div>
                                }
                                {leftData.map(element => {
                                    let fareBasicCode = element.fareBasisCode
                                    if (props.fareId) {
                                        fareBasicCode = `${props.fareId} ${element.fareBasisCode}`
                                    }
                                    if (props.flowFrom === "calculator_Existing_Fare") {
                                        fareBasicCode = `Existing ${element.fareBasisCode}`
                                    }
                                    if (flowFrom === "outBound") {
                                        fareBasicCode = `outBound ${props.fareId}${element.fareBasisCode}`
                                    }
                                    if (flowFrom === "inBound") {
                                        fareBasicCode = `inBound ${props.fareId}${element.fareBasisCode}`
                                    }
                                    console.log("element1234", element)
                                    if (element.segmentDirection === props.segmentDirection)
                                        return (
                                            <div className="fare-basic-bold mt-1" onClick={() => scrollTo(fareBasicCode)} >

                                                <a >
                                                    {element.fareBasisCode}
                                                </a>
                                            </div>
                                        )
                                })}

                                    {/* {leftData.length > 1 &&
                                      <p style={{fontSize:"12px"}}>
                                       {t("Fare results is a combination of multiple")}
                                     </p>} */}
                                {leftData && leftData.map(element => {
                                    let fareBasicCode = element.fareBasisCode
                                    if (props.fareId) {
                                        fareBasicCode = `${props.fareId} ${element.fareBasisCode}`
                                    }
                                    if (props.flowFrom === "calculator_Existing_Fare") {
                                        fareBasicCode = `Existing ${element.fareBasisCode}`
                                    }
                                    if (flowFrom === "outBound") {
                                        fareBasicCode = `outBound ${props.fareId}${element.fareBasisCode}`
                                    }
                                    if (flowFrom === "inBound") {
                                        fareBasicCode = `inBound ${props.fareId}${element.fareBasisCode}`
                                    }
                                    let fareRuleDetails = element.FareRuleDetails ? element.FareRuleDetails : []
                                    if (fareRuleDetails) {
                                        let index = fareRuleDetails.findIndex(obj => obj.title === "PENALTIES")
                                        if (index > 0) {
                                            let leftPenalty = fareRuleDetails.filter(obj => obj.title === "PENALTIES")
                                            fareRuleDetails = [...leftPenalty, ...fareRuleDetails]
                                        }
                                    }

                                    let fareRulesError = element.error ? element.error : undefined;
                                    if (element.segmentDirection === props.segmentDirection)
                                        return (<>
                                            <div className="ruleData" id={`${fareBasicCode}`}>

                                                <span className="code">{element.fareBasisCode} {" "}
                                                {lang === "fr" &&
                                                    <span style={{fontSize:"0.75rem",color:"red"}}>
                                                        L'information ci-dessous est disponible en anglais seulement
                                                    </span>
                                                    }
                                                </span>
                                                

                                                {fareRuleDetails &&
                                                    fareRuleDetails.map((element, index) => {

                                                        return (

                                                            <Accordion >
                                                                <Card>
                                                                    <Accordion.Toggle as={Card.Header} eventKey={index + 1} onClick={() => { handleClick(index + 1) }}>
                                                                        <a>{titleCase(element.title)} </a>
                                                                        {tabIdex === index + 1
                                                                            ? <button
                                                                                type="button"

                                                                                onClick={() => { handleClick(index + 1) }}
                                                                            >
                                                                                -
                                                                            </button>
                                                                            : <button
                                                                                type="button"

                                                                                onClick={() => { handleClick(index + 1) }}
                                                                            >
                                                                                +
                                                                            </button>}


                                                                    </Accordion.Toggle>

                                                                    {tabIdex === index + 1 &&
                                                                        <Accordion.Collapse eventKey={index + 1}>

                                                                            <Card.Body>
                                                                                {element.text.split(/\n/).map((line, newIndex) =>
                                                                                    <>{titleCase(line)}<br /></>)}

                                                                            </Card.Body>
                                                                        </Accordion.Collapse>}
                                                                </Card>
                                                            </Accordion>



                                                        );
                                                    })}

                                                {
                                                    fareRulesError ?
                                                        <div className="penalty-error-msg">
                                                            <i className="fa fa-exclamation-circle"></i>
                                                            <span style={{ position: "relative", bottom: "7px" }} onClick={() => handleErrorClick(fareRulesError.message)}>{t('112')}<br/>{t('156')} </span>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        </>)

                                })}


                                {/* <p>Fare results is a combination of multiple fare basis. Penalties are subject to multiple fees and conditions. Refer to RULES of each fare basis.</p> */}


                            </>
                            :
                            <div className="message_font">
                                {t('1005')}
                            </div>
                        }
                    </>
                    :
                    <div style={{ fontSize: "12px" }} className="ruleData">
                        <div className="penalty-error-msg">
                            <i className="fa fa-exclamation-circle"></i>
                            <span style={{ position: "relative", bottom: "7px" }}>{t('112')}<br/>{t('156')} </span>
                        </div>
                    </div>
                }

            </div>
            <Dialog
                open={showDialog}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="bd-pi-contact-modal bd-alertBox">
                    {dialogMessage}
                    <div className="btn-wrapper">
                        <button className="yes" onClick={handleOk}>{t("OK")}</button>
                    </div>
                </div>
            </Dialog>
        </>

    );
}

export default PenaltyTabDataMulticity;
