import React, { useEffect, useState } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SeatUserCnx from "./SeatUserCnx";
import SelectSeatMap from "./SelectSeatMap";
import { useDispatch, useSelector } from "react-redux";
import { convertCamelCase,splitFromCapital } from "./../../utils/utility/commenFunctions"
import { getSeatMapData } from "../../appStore/thunks/getSeatMap";
import { getSeatMapDataAfterPnr } from "../../appStore/thunks/getSeatMapAfterPnr"
import { setSeatMapDetail, setSeat, setFlightData, setSaveSeat } from "../../appStore/actionCreators/itinenary"
import { getCarriersData } from "../../appStore/thunks/getCarriersData";
import { getOperatingCarrierData } from "../../appStore/thunks/getOperatingCarrierData";
import { getMarketingCarrierData } from "../../appStore/thunks/getMarketingCarrierData";
import SelectSeatMapNew from "./SelectSeatMapNew";
import { useTranslation } from "react-i18next";

function SelectSeat(props) {
    const bookingItinenaries = useSelector((state) => state.flightBooking.brandedItenaries);
    const quoteItenary = useSelector((state) => state.flightBooking.bookingItenaries);
    const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
    const recordLocator = useSelector((state) => state.flightBooking.pnr);
    const flightDetail = useSelector((state) => state.flightBooking);

    const [flightIndex, setindex] = useState(0)
    const [flightNumber, setFlightNumber] = useState(0)
    const [tabName, setTabName] = useState("")
    const [seatStatus, setSeatStatus] = useState("")
    const [passengerId, setId] = useState("")
    const [flightData, setSingleFlight] = useState({})
    const [open, setOpen] = React.useState(false);
    let seatmapHeader = useSelector((state) => state.flightBooking.seatmapHeader)
    const orgId = useSelector((state) => state.agentProfile.orgId);
    const agencyId = useSelector((state) => state.agentProfile.agencyId);
    const agencyGroupId = useSelector((state) => state.agentProfile.agencyGroupId);
    const userId = useSelector((state) => state.agentProfile.userId);
    const providerId = useSelector((state) => state.searchForm.providerId);
    const currency = bookingData?.itineraryFare?.totalAmount?.currency
    const seatMapArray = useSelector((state) => state.itinenaries.seatMap);
    const seatData = useSelector((state) => state.itinenaries.seat);
    let platingCarrier =  flightDetail && flightDetail.bookingDetails &&  
                            flightDetail.bookingDetails.itineraryFare && 
                            flightDetail.bookingDetails.itineraryFare.platingCarrierDetails &&
                            flightDetail.bookingDetails.itineraryFare.platingCarrierDetails.carrier;
    
    if(props.from === "booking"){
        platingCarrier = flightDetail && flightDetail.bookingItenaries &&     
        flightDetail.bookingItenaries.platingCarrierDetails &&
        flightDetail.bookingItenaries.platingCarrierDetails.carrier;
    }                      
    let newKey;

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    let iternaryFlights = []
    let iternary = []
    let cancelledFlightCode = ["HX","UC","UN","NO","WK"]

    if (props.from === "confirmation" || props.from === "edit") {
        iternary = bookingData?.segments
    } else {
        iternary = Array.isArray(bookingItinenaries) ? bookingItinenaries : bookingItinenaries && bookingItinenaries.segments
    }

    if (iternary && iternary.length > 0) {
        iternary.map(obj => {
            let key = obj && Object.keys(obj) && Object.keys(obj)[0]
            let flights = obj && obj[key] && obj[key].flights && obj[key].flights
            let flightsFilteredData = flights && flights.filter(flightData => !cancelledFlightCode.includes(flightData?.statusDetail?.code))
            if(flightsFilteredData && flightsFilteredData.length > 0){
            iternaryFlights = [...iternaryFlights, ...flightsFilteredData]
            }
        })
    }

    let bookingFareId = useSelector((state) => state.searchForm.bookingFareId);
    const flightIds = useSelector((state) => state.itinenaries.flightData);
    const dispatch = useDispatch();
    useEffect(() => {

        let obj = iternaryFlights[0]
        let key = obj?.departureAirport + `-` + obj?.arrivalAirport
        let firstObj = iternaryFlights[0]
        let newflightId = iternaryFlights[0] && iternaryFlights[0].id
        let newflightNumber = iternaryFlights[0] && iternaryFlights[0].number
        let newKeyData = newflightId + `-` + newflightNumber

        setSingleFlight(firstObj)
        newKey = key + `_` + 0
        setTabName(key + `_` + 0)
        let filterData = seatMapArray && seatMapArray.filter(obj => obj[newKeyData])
        let data = filterData ? filterData : []
        getSeatMapDetail(data, firstObj, newflightId, newflightNumber)


        setFlightNumber(firstObj && firstObj.number)

    }, [])



    const getSeatMapDetail = async(data, firstObj, newflightId, flightNumber) => {
        

       
        if (data && data.length === 0) {
            if (props.from === "confirmation" || props.from === "edit" || props.from === "savedQuotes") {

                let fareBasisDetails = []
                if (props.from === "confirmation" || props.from === "edit") {
                    fareBasisDetails = bookingData?.itineraryFare?.paxFares[0]?.fareBasisDetails
                } else if (props.from === "savedQuotes") {
                    fareBasisDetails = quoteItenary?.paxFares[0]?.fareBasisDetails
                } else {
                    fareBasisDetails = bookingItinenaries?.itineraryFare?.paxFares[0]?.fareBasisDetails
                }

                let arr = fareBasisDetails && fareBasisDetails.filter(obj => obj.flightId === newflightId)
                let obj = arr && arr.length > 0 ? arr[0] : {}
                let brandData =obj.hasOwnProperty("brand")? obj.brand:{}
                var brand
                if(Object.keys(brandData).length>0){
                    brand ={
                        code:brandData.code
                    }
                }

                let payload = {

                    organizationId: orgId,
                    agencyId: agencyId,
                    agencyGroupId: agencyGroupId,
                    userId: userId,
                    providerId: providerId,
                    number: firstObj?.number,
                    operatingFlightNumber: firstObj?.operatingFlightNumber,
                    carrier: firstObj?.carrier,
                    operatingCarrier: firstObj?.carrier,
                    departureAirport: firstObj?.departureAirport,
                    arrivalAirport: firstObj?.arrivalAirport,
                    arrivalDateTime: firstObj?.arrivalDateTime,
                    departureDateTime: firstObj?.departureDateTime,
                    classOfService: obj?.classOfService,
                    cabinType: obj?.cabin,
                    currency: currency,
                    recordLocator:recordLocator,
                    brand:brand


                }

               if(firstObj === undefined || cancelledFlightCode.includes(firstObj?.statusDetail?.code)) return
               let result = await dispatch(getSeatMapDataAfterPnr(payload, newflightId, flightNumber))
               
                
                let operatingCarrier = result && result.results && result.results.operatingCarrier
                let marketingCarrier = result && result.results && result.results.marketingCarrier

                dispatch(getCarriersData(platingCarrier))
                dispatch(getOperatingCarrierData(operatingCarrier))
                dispatch(getMarketingCarrierData(marketingCarrier))
               
            } else {
                let result = await dispatch(getSeatMapData(newflightId, bookingFareId))
                 
                let operatingCarrier = result && result.results && result.results.operatingCarrier
                let marketingCarrier = result && result.results && result.results.marketingCarrier
                
                dispatch(getCarriersData(platingCarrier))
                dispatch(getOperatingCarrierData(operatingCarrier))
                dispatch(getMarketingCarrierData(marketingCarrier))
               
            }
        } else {
            

            dispatch(setSeatMapDetail(data && data[0] && data[0][newflightId + `-` + flightNumber]))
            let seatCarrier = data && data[0] && data[0][newflightId + `-` + flightNumber]
            let operatingCarrier = seatCarrier && seatCarrier.operatingCarrier
            let marketingCarrier = seatCarrier && seatCarrier.marketingCarrier

            dispatch(getCarriersData(platingCarrier))
            dispatch(getOperatingCarrierData(operatingCarrier))
            dispatch(getMarketingCarrierData(marketingCarrier))
        }
    }


    useEffect(() => {
    }, [flightIds])

    // validation function Added
    const validationOfSeat = () => {
     
        let filterPaxTypePass = props.passengerData && props.passengerData.filter(val => val.paxType !== "InfantInLap")
        let filterData = seatData && seatData.filter(obj => obj.flightNumber === flightData.number)
        
        if(filterData.length > 0 && filterData.length !== filterPaxTypePass.length){
           return true
        } else{
           return false
        } 
   
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseOnYes = (status) => {       
        setOpen(false);  
    };
    
    const handleClose = (status) => {

        let filterData = seatData && seatData.filter(obj => obj.flightNumber !== flightData.number)
        dispatch(setSeat(filterData));
        setOpen(false);  
    };


    // validation function End

    const getPassengerId = (id) => {
        let data = flightIds
        if(id){
            data.passengerId = id
        }
       
        data.flightNumber = flightIds.flightNumber
        dispatch(setFlightData(data))
        setId(id)
    }

    const getTab = (e, obj, i) => {
        if(props.from === "confirmation"){
            // dispatch(getCarriersData(platingCarrier))
            // dispatch(getOperatingCarrierData(operatingCarrier))
            // dispatch(getMarketingCarrierData(marketingCarrier))
        }
        
        let validation = validationOfSeat() 
        if(validation){
         handleClickOpen()
        }else{
                setId("")
                setindex(i)
                setSingleFlight(obj)
            //   dispatch(setSeatMapDetail([]));
                let key = e.split("_")[0]
                let index = e.split("_")[1]
                let firstObj = iternaryFlights && iternaryFlights[index]
                let newflightId = firstObj.id
                let flightNumber = firstObj.number
                let newKey = newflightId + `-` + flightNumber
                let filterData = seatMapArray && seatMapArray.filter(obj => obj[newKey])
                let data = filterData ? filterData : []

                getSeatMapDetail(data, firstObj, newflightId, flightNumber)
                setTabName(e)
                dispatch(setFlightData({ id: newflightId, index: index, flightNumber: firstObj && firstObj.number }))
                setFlightNumber(firstObj && firstObj.number)
        }
    }

    const seatSelected = (status) => {
        setSeatStatus(status)
    }

    const closeSeatMap = () => {
        dispatch(setSaveSeat(seatData));
        let validation = validationOfSeat() 
        if(validation){
            handleClickOpen()
        }else{
        if (props.from === "confirmation") {
            props.updateSeat()
            props.closeSeatMap()
            
        } else {
            props.addSeat()
            props.closeSeatMap()
        }
        }
    }

    const nextFlight = (i) => {
        let index = i + 1
        let firstObj = iternaryFlights[index]
        if (firstObj) {
            let key = firstObj.departureAirport + `-` + firstObj.arrivalAirport
            let newflightId = firstObj.id
            let newFlightNumber = firstObj.number
        
            setTabName(key + `_` + index)
            let newKeyData = newflightId + `-` + newFlightNumber
            newKey = key + `_` + index
            let filterData = seatMapArray && seatMapArray.filter(obj => obj[newKeyData])
            let data = filterData ? filterData : []
            getSeatMapDetail(data, firstObj, newflightId, newFlightNumber)
            setindex(index)
            setFlightNumber(firstObj && firstObj.number)
            setSingleFlight(firstObj)
        }
    }
    let passengerDataWithountInfant = props.passengerData && props.passengerData.filter(val => val.paxType !== "InfantInLap") 

    const { t } = useTranslation();
    return (
        <>

            <div className={props.isSeat ? `seatView-container open  selectSeat-container confirmation` : `seatView-container selectSeat-container`}>
                <div className="seatView-overlay"></div>
                <div className="seatView-main">

                    <div className="seatV-top">
                        <div className="head">{t("Select Seat")}</div>
                        <div className="seatS-cabin">
                            <ul className="seatV-cabin">
                                <li>{seatmapHeader.brandName ? convertCamelCase(seatmapHeader.brandName) : seatmapHeader.fareType} : <strong>{ `${window.selectedDollarValue} `} {seatmapHeader.totalFare}</strong></li>
                                <li>{seatmapHeader.cabin ? " "+ t("Cabin") + ":" + (seatmapHeader.cabin && t(splitFromCapital(seatmapHeader.cabin))) : seatmapHeader && seatmapHeader.validatingCarrier}</li>
                            </ul>
                            <span className="seatV-close" onClick={closeSeatMap}>×</span>
                        </div>
                    </div>

                    <div className="sv-tabs">
                        {/* new design start */}
                      
                        {iternaryFlights &&
                            <>
                                <Tab.Container id="left-tabs-example" activeKey={tabName}>
                                    <Nav variant="tabs">
                                        <div className="ss-tab-slider">
                                            <Slider {...settings}>
                                                {iternaryFlights.map((obj, i) => {
                                                    let key = obj.departureAirport + `-` + obj.arrivalAirport
                                                    let tabkey = tabName && tabName.split("_") && tabName.split("_")[0]

                                                    let newKey = key + '_' + i
                                                    return (
                                                        <Nav.Link eventKey={newKey} onSelect={(e) => getTab(e, obj, i)}>{key}</Nav.Link>
                                                    )
                                                })}

                                            </Slider>
                                        </div>
                                    </Nav>
                                    <Tab.Content>

                                        <Tab.Pane eventKey={tabName}>
                                            <div className="seatS-row">
                                                <div className="seatUser-col">
                                                    <SeatUserCnx
                                                        flight={flightData}
                                                        // passengerData={props.passengerData}
                                                        passengerData={passengerDataWithountInfant}
                                                        addSeat={props.addSeat}
                                                        updateSeat={()=>props.updateSeat()}
                                                        passengerId={getPassengerId}
                                                        closeSeatMap={() =>closeSeatMap()}
                                                        seatStatus={seatStatus}
                                                        from={props.from}
                                                        class={props.class}
                                                        nextFlight={() => nextFlight(flightIndex)}
                                                        validationOfSeat={() => validationOfSeat()}
                                                        handleClickOpen={() => handleClickOpen()}
                                                        handleCloseOnYes={()=> handleCloseOnYes()}
                                                        open={open}
                                                        handleClose={()=> handleClose()}
                                                        index={flightIndex}
                                                        flightCount={iternaryFlights && iternaryFlights.length}

                                                    />

                                                </div>
                                                <div className="seatMap-col">
                                                    <SelectSeatMapNew seatSelected={seatSelected} passengerId={passengerId} class={props.class} passengerData={props.passengerData} flightIndex={flightIndex}/>
                                                </div>
                                            </div>
                                        </Tab.Pane>



                                    </Tab.Content>
                                </Tab.Container>
                            </>
                        }


                    </div>

                </div>
            </div>
        </>
    );
}

export default SelectSeat;