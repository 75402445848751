import { setfreeTextFareRules,setstructuredFareRules } from '../actionCreators/penaltiesTab';
import { Penalties, TripType } from '../../utils/constant';
import { env } from '../../appConfig'
import getApiResponse from '../../services/getApiResponse'



export const getPenaltiesDataForPNR = (recordLocator) => {


    return async (dispatch, getState) => {
        let group = [];
        let textFareRule=[];
        const corrId = getState().searchForm.uuid;
        const tripTypeValue = getState().searchForm.tripType;
        const penaltiesTextFareData = getState().Penalaties.freeTextFareRules;
        penaltiesTextFareData && textFareRule.push(...penaltiesTextFareData);
        const orgId = getState().agentProfile.orgId;
        const agencyId = getState().agentProfile.agencyId;
        const agencyGroupId = getState().agentProfile.agencyGroupId;
        const flightType = getState().searchForm.flightType;
        const isInternalUserAutheticated = getState().internalUser.isInternalUserAutheticated;
        const bookingChannel = isInternalUserAutheticated ? "internal" : "external";
        
        // Api For FareRules
       
        // let url = env.REACT_APP_API_BASE_URL+`/revisedFareRules/freeText`

        // const requestOptions = {
        //     method: "POST",
        //     headers: {
        //         "Content-type": "application/json; charset=UTF-8",
        //         "Access-Control-Allow-Origin": "*",
                
        //         "Access-Control-Allow-Credentials" : true,
        //         correlationId: corrId,
        //         flightType:flightType

        //     },
        //     body:
        //         JSON.stringify(
        //             {
        //                 organizationId: orgId ,
        //                 agencyId: agencyId,
        //                 agencyGroupId: agencyGroupId ,
        //                 recordLocator: recordLocator
        //             })
        // }      

        // Axios payload
        const config = {
            method: "POST",
            url: env.REACT_APP_API_BASE_URL+`/pnrFareRules/freeText`,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                
                "Access-Control-Allow-Credentials" : true,
                correlationId: corrId,
                flightType:flightType,
                bookingChannel:bookingChannel

            },
            data:{
                organizationId: orgId ,
                agencyId: agencyId,
                agencyGroupId: agencyGroupId ,
                recordLocator: recordLocator
            }
        }
      


       let penaltiesResultFareRules = await getApiResponse(config);

        let key = recordLocator
        
        if (penaltiesResultFareRules.error) {
            
            const result = {
                [key]: Penalties.STR_FARE_RULES_ERROR,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
        } else {
            
            const result = {
                [key]: penaltiesResultFareRules.fareRules,
            };
            textFareRule.push(result);
            dispatch(setfreeTextFareRules(textFareRule));
        }
       
       
    }
}