import React ,{useEffect, useState} from "react";
import { shortFormFareType } from "../../utils/utility/convertFareTypeShortForm";
// import moment from "moment";
import { useSelector } from "react-redux";
// import { TripType } from "../../utils/constant"
import loyalty from "../../Assets/loyalty-icon.svg";
import frenchLoyalty from "../../Assets/loyalityFR.png";
import "../../sass/skeleton.css";
import FareBreakDown from "../bookings/fareBreakdown"
import { getFlightLogo } from "../../utils/utility/commenFunctions";
import { PassengerType } from '../../utils/constant';
import { useTranslation } from "react-i18next";
function BookingItinerayInfo(props) {
  // const tripTypeValue = useSelector((state) => state.searchForm.tripType);
  // const flyingFrom = useSelector((state) => state.searchForm.flyingFrom);
  // const flyingTo = useSelector((state) => state.searchForm.flyingTo);
  const lang = useSelector( (state) => state.lang.lang)
  const priceValue = useSelector((state) => state.flightBooking.bookingItenaries);
  const priceStatus = useSelector((state) => state.flightBooking.priceStatus);
  const promotion = useSelector((state) => state.searchForm.promotionData);
  const bookingData = useSelector((state) => state.flightBooking.bookingDetails);
  let agencyMarkups = bookingData && bookingData.agencyMarkups
  const totalAmount = priceValue && priceValue.totalAmount && priceValue.totalAmount.amount
  let adultMarkup = useSelector((state) => state.searchForm.adultMarkupValue);
  let childMarup = useSelector((state) => state.searchForm.childMarkupValue);
  let infantMarkup = useSelector((state) => state.searchForm.infantMarkupValue);
  let adultCount = useSelector((state) => state.searchForm.passengerAdult);
  let childsCount = useSelector((state) => state.searchForm.passengerChildren);
  let infantsCount = useSelector((state) => state.searchForm.passengerInfant);
  
  const [skeletonItineryInfo, setSkeletonItineryInfo] = React.useState(priceStatus);
  let totalAmountByCal =0
  let adultsCount =0
  let childCount =0
  let infantCount = 0
  let infantMarkupValue =0
  let childMarkupValue =0
  let adultMarkupValue =0
 if(agencyMarkups && agencyMarkups.length > 0){
  let adultsData =agencyMarkups.filter(obj => obj.paxType===PassengerType.ADULT)
  let infantData = agencyMarkups.filter(obj => obj.paxType===PassengerType.INFANTINLAP)
  let childData = agencyMarkups.filter(obj => obj.paxType===PassengerType.CHILD)
  if(adultsData && adultsData[0] ){
    adultsCount = adultsData[0].quantity
    adultMarkupValue = adultsData[0].perPaxAmount && adultsData[0].perPaxAmount.amount
  }
  if(childData && childData[0]){
    childCount =  childData[0].quantity
    childMarkupValue =childData[0].perPaxAmount && childData[0].perPaxAmount.amount
  }
  if(infantData && infantData[0]){
    infantCount = infantData[0].quantity
    infantMarkupValue = infantData[0].perPaxAmount && infantData[0].perPaxAmount.amount
  }
  totalAmountByCal = totalAmount
 } else{

    adultsCount = adultCount
    childCount = childsCount
    infantCount = infantsCount
    adultMarkupValue = adultMarkup
    childMarkupValue = childMarup
    infantMarkupValue = infantMarkup
 
  let totalAmountSumm = Number(totalAmount)
  + (Number(adultsCount) * Number(adultMarkupValue)) 
  + (Number(childCount) * Number(childMarkupValue))
  + (Number(infantCount) * Number(infantMarkupValue))
  totalAmountByCal = totalAmountSumm && Number(totalAmountSumm).toFixed(2)
 }


  // let date = props.bookingHeader.ticketingTimeLimit;
  
  let carrierCode = props.bookingHeader.validatingCarrier&&props.bookingHeader.validatingCarrier
  let flightLogo = getFlightLogo(carrierCode)

 const [open,setOpen] = useState(false)

  useEffect(()=>{
    setSkeletonItineryInfo(priceStatus)
  },[priceStatus])

  const openfareBreakdoun =()=>{
    setOpen(!open)
  }

  const { t } = useTranslation();

  return (
    <>
  

      <div className="bd-itineray-info">
        <ul className="bd-itineray-info-list">
          <li className="airportLogo">
            <img src={flightLogo} alt="ac imag" />
            <span className="airportName">
              {props.bookingHeader.validatingCarrier}
            </span>
          
          </li>
          <li className="price">
            {totalAmountByCal === props.bookingHeader.totalFare ? Number(props.bookingHeader.totalFare).toFixed(2) : Number(totalAmountByCal).toFixed(2)}
            <i className="fas fa-info-circle" onClick={openfareBreakdoun}></i>{" "}
            <FareBreakDown 
            open={open} 
            handleClose={openfareBreakdoun} 
            from={props.from}/>
          </li>
          <li className="intineray-infoPub">
            {props.bookingHeader.fareType == "NotSpecified" ? (
              <>
                {" "}
                --- <i className="fas fa-star"></i>{" "}
              </>
            ) : (
              <>
                {" "}
                <i className="fas fa-star"></i>
                {shortFormFareType(props.bookingHeader.fareType)}{" "}
              </>
            )}
          </li>
         
          {promotion &&  
                 
          <li className="flareLoyalty">
              <img src={lang !== "fr" ? loyalty : frenchLoyalty} className="img-fluid loyalty" alt="loyalty" width={"45 rem"} height={"30 rem"} style={{"width":"2.813 rem","height":"1.438 rem"}}/>
              {promotion.loyaltyPoints}
          </li>
                
              
              }

          <li className="flareOption">
              <span>{t("Fare Option")}</span>{props.bookingHeader.brandName}
          </li>

          {/* {tripTypeValue === "MultiCity" ? (
            <>
              <div className="w-30">
                <li>{Object.keys(props.bookingHeader.segment[0])}</li>
                <span>
                  {maxValue === 0
                    ? "Direct"
                    : " Upto" + " " + maxValue + " cnx"}{" "}
                </span>
              </div>
              <li>+ {props.bookingHeader.segment.length - 1 + " City Pairs"}</li>
            </>
          ) : (
            <>
              <li className="intineray-route">
                {Object.keys(props.bookingHeader.segment[0])}
                <span className="flightRoot">
                  {Object.values(props.bookingHeader.segment[0])[0]
                    .connections === 0
                    ? "Direct"
                    : Object.values(props.bookingHeader.segment[0])[0]
                      .connections + " cnx"}
                </span>
              </li>
              <li className="intineray-route">
                {!props.bookingHeader.segment[1] ? (
                  ""
                ) : (
                  <>
                    {Object.keys(props.bookingHeader.segment[1])}
                    <span className="flightRoot">
                      {Object.values(props.bookingHeader.segment[1])[0]
                        .connections === 0
                        ? "Direct"
                        : Object.values(props.bookingHeader.segment[1])[0]
                          .connections + " cnx"}{" "}
                    </span>
                  </>
                )}
              </li>
            </>
          )} */}
        </ul>
      </div>
    {/* } */}
    </>
  );
}

export default BookingItinerayInfo;
