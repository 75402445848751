export const msg = {
  // error messages //
  101: "Please specify your agency or phone number and your agent name",
  102: "Please specify your agency number or phone number",
  103: "Please specify your agent name",
  104: "Please specify origin and destination",
  105: "Please specify dates",
  106: "Please specify airline",
  107: "Cannot perform search, please try again",
  108: "Please select the correct fare",
  109: " We are sorry; the airline system has not responded and we are unable to provide the seat map",
  110: "You must create a booking before selecting paid seats. Fees for paid seats may change after booking. ",
  111: "Please wait for the tickets to be issued before selecting paid seats.",
  112: "We are sorry; the airline has not responded, and we are unable to provide the fare rules.",
  113: "A technical problem occurred, please try again",
  114: "Please enter a value (0 or more)",
  115: "Please fill all required fields",
  // "116":"Please specify a gender", replaced by yash
  117: "Failed to send email, please try again. ",
  // "118":"Failed to update the quote, please contact us.", commented
  119: "Your quote is no longer valid.  Please do a new search.",
  120: "An error occurred while saving the fare, please retry.",
  121: "Please specify record locator",
  122: "Please specify booking source",
  123: `Please insert  to search`,
  124: "Please specify full name",
  125: "Please select number of infants less than or equal to number of adults.",
  126: "Please add Booking Number",
  127: "Invalid Booking Number",
  128: "Please Select Valid Dates",
  129: "Server took longer to respond. Please verify from My Files.",
  130: "Price could not be saved in the PNR. Please try to Save again.",
  131: "There is a delay to issue your tickets. Please redisplay and review your file again.",
  132: "Failed to Add/Update Markup,Please Try Again",
  133: "could not be saved in the PNR",
  134: "Please Fill Mandatory Passport Details ",
  135: "Passport Details Are Mandatory for this Airline",
  136: "Gender can not be blank",
  137: "Please select valid expiration date",
  138: "Please select valid birth date",
  139: "Adult age must be greater than 11 years",
  140: "Inflant age must be less than 2 years",
  141: "Payment for prepaid seats was not attempted because tickets haven’t been issued yet.",
  142: "Prepaid seat Selection removed",
  143: "Server took longer to respond.",
  144: "Payment for prepaid seats was not attempted.",
  145: "We are sorry, some flights are no longer available in the required classes of service. Please try again or select other flights.",
  146: "The carrier requires this itinerary to be booked directly through Intair. Please use Gigi to search and book.",
  147: "The total number of adults should match that of infants.",
  148: "A child cannot travel alone and must be accompanied by an adult.",
  149: "Sabre is having issues confirming and saving the fares. Please start over.", //French-Sabre éprouve des problèmes à confirmer and enregistrer les tarifs. Veuillez recommencer.
  150:"Unable to fetch branded fares",
  151: "Technical error occur. Please contact us to make this booking.",
  152: "We are sorry, but online cancellation for this file is not allowed. Please contact us.",
  153: "Technical error occur.",
  154: "Please enter a valid DOB in the format DDMMMYY, e.g., 12AUG90",
  155: "Invalid dates: birthdate is after departure date",
  156: "Please check on the airline's website.",
  // Success Messages //
  // "501":"Your quote is saved", replaced by New price has been saved
  501: "New price has been saved",
  502: "Email sent",
  503: "Fare saved successfully",

  //  Inside Layout Messages //
  1001: "We are searching for the best fares available to you, including exclusive web fares from select airlines! Please be patient.",
  1002: "No results found for selected filters",
  1003: "No results found",
  1004: "Please modify your search criteria",
  1005: "Searching for fare rules…",
  1006: "Unable to display the details - No flight segment data available in the booking.",
  1007: "Your option is expired - online payment is not allowed.",
  1008: "Please wait while we save your changes",
  1009: "A technical error occurred, please try again",
  1010: "Payment is required today (until 23:59) to secure prepaid seats",
  1011: "*Name fields only accept letters",
  1012: "Passport Number fields only accept numbers and letters",
  1014: "Failed to Update Option Date,Please try again",
  1015: "Option Date Sucessfully Updated",
  1016: "Payment was only added for some travelers. Please contact customer service.",

  1017:"Please wait while a new search is made to confirm this fare and flight availability. Fares with other carrier will be also returned for comparison.",
  1018:"Electronic ticket email was not sent because ticket numbers are not present."
};
